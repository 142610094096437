import { ChooseTestsFilterOptions } from "../common/CreateTests.interfaces";
import { TestCategory, ExtendedTest } from "../common/CreateTests.interfaces";
import {
  getNewCategories,
  getTestsByTemplateId,
  isAnyCategorySelected,
} from "../common/CreateTestsCommonUtils";

export interface ChooseHealthScoreTestsState {
  filter: ChooseTestsFilterOptions;
  categories: TestCategory[];
}

export interface ChooseHealthScoreTestsAction {
  type:
    | "RESET"
    | "SET_CATEGORIES"
    | "TEST_UPDATE"
    | "TEST_SELECTION_CHANGE"
    | "UPDATE_SEARCH_TEXT"
    | "TOGGLE_SELECTED_TESTS"
    | "SHOW_ALL_CATEGORIES"
    | "TOGGLE_CATEGORY";
  payload: {
    categories?: TestCategory[];
    test?: ExtendedTest;
    isTestSelected?: boolean;
    searchText?: string;
    showSelected?: boolean;
    isCategorySelected?: boolean;
    categoryId?: string;
  };
}

// eslint-disable-next-line complexity
export function chooseHealthScoreTestsReducer(
  chooseTestsState: ChooseHealthScoreTestsState,
  chooseTestsAction: ChooseHealthScoreTestsAction,
): ChooseHealthScoreTestsState {
  switch (chooseTestsAction.type) {
    case "RESET":
      const categories = chooseTestsState.categories.map((cat) => ({
        ...cat,
        selected: false,
      }));
      const filter = {
        ...chooseTestsState.filter,
        showAllCategories: true,
        showSelected: true,
      };
      return { categories, filter };

    case "SET_CATEGORIES":
      return chooseTestsAction.payload.categories
        ? {
            ...chooseTestsState,
            categories: chooseTestsAction.payload.categories,
          }
        : chooseTestsState;

    case "TEST_UPDATE":
      return {
        ...chooseTestsState,
        categories: getNewCategories(
          chooseTestsState.categories,
          chooseTestsAction.payload.test,
        ),
      };

    case "TEST_SELECTION_CHANGE":
      if (!chooseTestsAction.payload.test) {
        return chooseTestsState;
      }
      const { test } = chooseTestsAction.payload;
      const testToUpdate = getTestsByTemplateId(
        chooseTestsState.categories,
        test.data.base.code,
      );
      const isNewTestExpanded = testToUpdate.extended.expanded;
      const testToBe: ExtendedTest = {
        data: {
          ...testToUpdate.data,
        },
        extended: {
          ...testToUpdate.extended,
          selected: Boolean(chooseTestsAction.payload.isTestSelected),
          expanded: isNewTestExpanded,
        },
      };
      return {
        ...chooseTestsState,
        categories: getNewCategories(chooseTestsState.categories, testToBe),
      };

    case "UPDATE_SEARCH_TEXT":
      if (
        chooseTestsAction.payload.searchText !== undefined &&
        chooseTestsAction.payload.searchText !==
          chooseTestsState.filter.textToSearchBy
      ) {
        return {
          ...chooseTestsState,
          filter: {
            ...chooseTestsState.filter,
            textToSearchBy: chooseTestsAction.payload.searchText,
          },
        };
      }
      return chooseTestsState;

    case "TOGGLE_SELECTED_TESTS":
      return chooseTestsAction.payload.showSelected !== undefined
        ? {
            ...chooseTestsState,
            filter: {
              ...chooseTestsState.filter,
              showSelected: chooseTestsAction.payload.showSelected,
            },
          }
        : chooseTestsState;

    case "SHOW_ALL_CATEGORIES":
      const newCategories = chooseTestsState.categories.map((category) => {
        return {
          ...category,
          selected: false,
        };
      });
      return {
        categories: newCategories,
        filter: { ...chooseTestsState.filter, showAllCategories: true },
      };

    case "TOGGLE_CATEGORY":
      const newCats = chooseTestsState.categories.map((category) => {
        return {
          ...category,
          selected:
            category.code === chooseTestsAction.payload.categoryId
              ? Boolean(chooseTestsAction.payload.isCategorySelected)
              : category.selected,
        };
      });
      const showAllCategories = !isAnyCategorySelected(newCats);
      return {
        categories: newCats,
        filter: { ...chooseTestsState.filter, showAllCategories },
      };

    default:
      return chooseTestsState;
  }
}

export const initialChooseHealthScoreTestsFormValue: ChooseHealthScoreTestsState =
  {
    categories: [],
    filter: {
      showSelected: true,
      showAllCategories: true,
      textToSearchBy: "",
    },
  };
