import { ApolloQueryResult, insertIf, useSession } from "@lumar/shared";
import { ConnectCiCdTool } from "./components/ConnectCiCdTool/ConnectCiCdTool";
import { UpdateConnectSite } from "./components/ConnectSiteForm/UpdateConnectSite";
import { CrawlSettingsForm } from "./components/CrawlSettingsForm/CrawlSettingsForm";
import { SetAlertsForm } from "./components/SetAlerts/SetAlertsForm";
import { CustomStep } from "./CustomStep";
import { StepChangeHandler } from "./UpdateTestSuite";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
  useGetAutomatorReportTemplatesQuery,
  useGetTestSuiteHealthScoreTestsQuery,
  useGetTestSuiteReportTestsAndCustomExtractionsQuery,
} from "../../graphql";
import {
  ChooseTestsForm,
  ChooseTestsFormProps,
} from "./components/ChooseTests/ChooseTestsForm/ChooseTestsForm";
import { ChooseHealthScoreTestsForm } from "./components/ChooseTests/ChooseHealthScoreTestsForm/ChooseHealthScoreTestsForm";
import {
  chooseTestsReducer,
  initialChooseTestsFormValue,
} from "./components/ChooseTests/ChooseTestsForm/chooseTestsReducer";
import { useReducer, useRef, useState } from "react";
import {
  chooseHealthScoreTestsReducer,
  initialChooseHealthScoreTestsFormValue,
} from "./components/ChooseTests/ChooseHealthScoreTestsForm/chooseHealthScoreTestsReducer";
import { MAX_TESTS_SELECTION_LIMIT } from "./components/ChooseTests/ChooseTestsForm/CreateTestsCommonConst";
import {
  getSelectedTests,
  isMaxTestSelectedLimitReached,
} from "./components/ChooseTests/common/CreateTestsCommonUtils";
import {
  PopoverDialog,
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers,
} from "../../_common/components/PopoverDialog/PopoverDialog";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

interface UpdateTestSuiteStepsProps
  extends Omit<
    ChooseTestsFormProps,
    | "onSuccess"
    | "useTestSuiteTestsAndExtractionsQueryObject"
    | "reportTemplateObject"
    | "reducer"
    | "disableSelection"
  > {
  absoluteThresholdUpLimit: number;

  refetchTestSuite: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
  testSuiteId: string;
  handleStepChange: (
    step: string,
    shouldExpandNextStep?: boolean | undefined,
  ) => StepChangeHandler;
  expandedStep: string;
  useReportTemplateObject: ReturnType<
    typeof useGetAutomatorReportTemplatesQuery
  >;
  data: GetTestSuiteQuery;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Ok",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "secondary",
  },
];

export function UpdateTestSuiteSteps(
  props: UpdateTestSuiteStepsProps,
): JSX.Element {
  const session = useSession();

  const { testSuite, data, project, handleStepChange } = props;

  const hasHealthScore =
    session.hasAddon("health-scores") &&
    session.hasFeatureFlagEnabled("protect-health-score-alerts");
  const doesParentExist = Boolean(testSuite?.parent);

  const useTestSuiteTestsAndExtractionsQueryObject =
    useGetTestSuiteReportTestsAndCustomExtractionsQuery({
      variables: { id: props.testSuiteId },
      notifyOnNetworkStatusChange: true,
    });
  const useTestSuiteHealthScoreTests = useGetTestSuiteHealthScoreTestsQuery({
    variables: { id: props.testSuiteId },
    notifyOnNetworkStatusChange: true,
  });

  function refetchData() {
    useTestSuiteTestsAndExtractionsQueryObject.refetch();
    props.refetchTestSuite();
  }

  const popupReference = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function openMaxTestSelectionPopup() {
    setAnchorEl(popupReference.current);
  }

  const chooseTestsReducerInstance = useReducer(
    chooseTestsReducer,
    initialChooseTestsFormValue,
  );

  const chooseHealthScoreTestsReducerInstance = useReducer(
    chooseHealthScoreTestsReducer,
    initialChooseHealthScoreTestsFormValue,
  );

  const [{ categories: healthScoreCategories }] =
    chooseHealthScoreTestsReducerInstance;

  const [{ categories }] = chooseTestsReducerInstance;

  const allCategories = [...healthScoreCategories, ...categories];

  const maxLimitOfSelectedTestsReached =
    isMaxTestSelectedLimitReached(allCategories);

  const [alreadySelectedTestsCount, setAlreadySelectedTestsCount] = useState(
    getSelectedTests(allCategories).length,
  );

  const newAlreadySelectedTestsCount = getSelectedTests(allCategories).length;

  if (
    newAlreadySelectedTestsCount === MAX_TESTS_SELECTION_LIMIT &&
    alreadySelectedTestsCount === MAX_TESTS_SELECTION_LIMIT - 1
  ) {
    openMaxTestSelectionPopup();
  }

  if (newAlreadySelectedTestsCount !== alreadySelectedTestsCount) {
    setAlreadySelectedTestsCount(newAlreadySelectedTestsCount);
  }

  const stepsConfigs = [
    {
      name: "Connect site",
      number: 1,
      pendoId: "auto-edit-test-suite-step1",
      description:
        "Configure the website that will be crawled during the test, along with any necessary authentication settings.",
      children: (
        <UpdateConnectSite
          refetchTestSuite={props.refetchTestSuite}
          testSuite={testSuite}
          project={project}
          onSubmit={handleStepChange("step2", !doesParentExist)}
        />
      ),
    },
    {
      name: "Choose crawl settings",
      number: 2,
      pendoId: "auto-edit-test-suite-step2",
      description:
        "Configure how the crawler treats your website and the URLs that will be crawled.",
      children: (
        <CrawlSettingsForm
          testSuite={testSuite}
          data={data}
          project={project}
          onSubmit={handleStepChange("step3", !doesParentExist)}
          refetchData={refetchData}
        />
      ),
    },
    {
      name: "Choose Report-based tests",
      number: 3,
      pendoId: "auto-edit-test-suite-step3",
      description:
        "While Lumar Protect crawls it will run the below tests. These will dictate the overall success of the build. The maximum number of tests that can be selected for each test suite is 100.",
      children: (
        <ChooseTestsForm
          absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
          hasChildren={props.hasChildren}
          smartThresholdSettings={props.smartThresholdSettings}
          setSmartThresholdSettings={props.setSmartThresholdSettings}
          testSuite={testSuite}
          project={project}
          onSuccess={handleStepChange("step4", true)}
          useTestSuiteTestsAndExtractionsQueryObject={
            useTestSuiteTestsAndExtractionsQueryObject
          }
          reportTemplateObject={props.useReportTemplateObject}
          reducer={chooseTestsReducerInstance}
          disableSelection={maxLimitOfSelectedTestsReached}
        />
      ),
    },
    ...insertIf(hasHealthScore, {
      name: "Choose Health Score-based tests",
      number: 4,
      pendoId: "auto-edit-test-suite-step6",
      description:
        "While Lumar Protect crawls it will run the below tests. These will dictate the overall success of the build. The maximum number of tests that can be selected for each test suite is 100.",
      children: (
        <ChooseHealthScoreTestsForm
          absoluteThresholdUpLimit={100}
          hasChildren={props.hasChildren}
          smartThresholdSettings={props.smartThresholdSettings}
          setSmartThresholdSettings={props.setSmartThresholdSettings}
          testSuite={testSuite}
          project={project}
          onSuccess={handleStepChange("step5", true)}
          useTestSuiteTestsQueryObject={useTestSuiteHealthScoreTests}
          reducer={chooseHealthScoreTestsReducerInstance}
          disableSelection={maxLimitOfSelectedTestsReached}
        />
      ),
    }),
    {
      name: "Set notifications",
      number: hasHealthScore ? 5 : 4,
      pendoId: "auto-edit-test-suite-step4",
      description: undefined,
      children: (
        <SetAlertsForm
          testSuite={testSuite}
          onSubmit={handleStepChange(`step${hasHealthScore ? 6 : 5}`, true)}
        />
      ),
    },
    {
      name: "Run test suite",
      number: hasHealthScore ? 6 : 5,
      pendoId: "auto-edit-test-suite-step5",
      description: undefined,
      children: (
        <ConnectCiCdTool
          testSuite={testSuite}
          refetchTestSuite={props.refetchTestSuite}
        />
      ),
    },
  ];

  return (
    <div ref={popupReference}>
      <PopoverDialog
        anchorElement={anchorEl}
        handleAction={handleClose}
        open={Boolean(anchorEl)}
        text={`The maximum number of tests that you can select is ${MAX_TESTS_SELECTION_LIMIT}. Please deselect existing tests before choosing more.`}
        title="Can’t select more tests"
        buttons={popoverButtons}
        id={`{simple-popover-warning-${MAX_TESTS_SELECTION_LIMIT}}`}
        centered={true}
        icon={<InfoRoundedIcon />}
      />
      {stepsConfigs.map((step) => {
        const isStep2or3 = step.number === 2 || step.number === 3;
        const isParentStep = isStep2or3 && props.hasChildren;
        const isChildStep = isStep2or3 && !!testSuite.parent;
        const isExpanded = isChildStep
          ? false
          : props.expandedStep === `step${step.number}`;

        return (
          <CustomStep
            disabled={isChildStep}
            key={step.number}
            isExpanded={isExpanded}
            handleChange={handleStepChange(`step${step.number}`)}
            stepHeader={step.name}
            stepNumber={step.number}
            id={`step${step.number}`}
            testId={`step${step.number}-collapse-container`}
            isParentStep={isParentStep}
            pendoId={step.pendoId}
            description={step.description}
            isChildStep={isChildStep}
          >
            {step.children}
          </CustomStep>
        );
      })}
    </div>
  );
}
