/* eslint-disable max-lines */
import {
  TestCategory,
  ExtendedTest,
  CUSTOM_EXTRACTION_CATEGORY,
} from "../common/CreateTests.interfaces";
import {
  recomendedTestCodeList,
  RECOMMENDED_TESTS_CATEGORY_CODE,
  recommendedTestsCategory,
} from "./CreateTestsCommonConst";
import { compareStrings } from "../../../../../_common/utils/string/comparisons/comparisons";
import {
  compareTestByName,
  getSelectedTests,
} from "../common/CreateTestsCommonUtils";
import { getCustomExtractionTestName } from "../../../../../_common/utils/getCustomExtractionTestName/getCustomExtractionTestName";
import { isCustomExtractionInReportTemplate } from "./utils/customExtractionWithReportTemplate";
import { getCustomExtractionTooltip } from "../../../../../_common/utils/getCustomExtractionTooltip/getCustomExtractionTooltip";
import {
  CreateTestsMutationVariables,
  GetAutomatorReportTemplatesQuery,
  GetTestSuiteQuery,
  GetTestSuiteReportTestsAndCustomExtractionsQuery,
  ModuleCode,
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
  ThresholdType,
  UpdateTestsMutationVariables,
} from "../../../../../graphql";

export type AllReportTemplates =
  GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0];

function getSelectedTestsFromData(
  tests: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >["tests"],
  reportTemplateCode: string,
) {
  return tests.nodes?.find(
    (test) => test.reportTemplate.code === reportTemplateCode,
  );
}

function getCategoryCode(
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
) {
  if (reportTemplate.node.code.startsWith("custom_extraction_")) {
    return CUSTOM_EXTRACTION_CATEGORY;
  }
  if (reportTemplate.node.category?.length) {
    return reportTemplate.node.category;
  } else {
    return "other";
  }
}

function isTestCodeInRecomendedList(
  code: string,
  moduleCode: ModuleCode,
): boolean {
  return recomendedTestCodeList[moduleCode]?.indexOf(code) !== -1;
}

function compareCategoryByName(catA: TestCategory, catB: TestCategory) {
  return compareStrings(catA.name, catB.name);
}

const thresholdList: Partial<
  Record<ModuleCode, { value: number; type: ThresholdType }>
> = {
  [ModuleCode.Accessibility]: { value: 1, type: ThresholdType.Absolute },
  [ModuleCode.Seo]: { value: 1, type: ThresholdType.Absolute },
  [ModuleCode.SiteSpeed]: { value: 1, type: ThresholdType.Absolute },
};

function getExtendTestFromTemplates(
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
  template: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >["tests"]["nodes"][0]["reportTemplate"],
  testSuiteModuleCode: ModuleCode,
): ExtendedTest {
  return {
    data: {
      id: "",
      relativeThreshold: thresholdList[testSuiteModuleCode]?.value ?? 1,
      createJiraTicketOnFailure: false,
      jiraTicketCustomNote: null,
      absoluteThreshold: thresholdList[testSuiteModuleCode]?.value ?? 1,
      thresholdType:
        thresholdList[testSuiteModuleCode]?.type ?? ThresholdType.Absolute,
      severity: Severity.Fail,
      base: {
        category: reportTemplate.node.category ?? "other",
        code: template.code,
        name: template.name,
        automatorSummary: template.automatorSummary,
        unit: reportTemplate.node.metadata?.unit ?? ReportTemplateUnit.UrLs,
      },
      createdAt: new Date(),
      updatedAt: new Date(),
      thresholdPredicate:
        (reportTemplate.node.totalSign ?? 0) <= 0
          ? ThresholdPredicate.GreaterThanOrEqual
          : ThresholdPredicate.LessThan,
      automaticThresholdEnabled: false,
    },
    extended: {
      selected: false,
      expanded: false,
      category: "",
      recomended: isTestCodeInRecomendedList(
        reportTemplate.node.code,
        testSuiteModuleCode,
      ),
      moduleCode: testSuiteModuleCode,
    },
  };
}

function getTestNameForCustomExtraction(
  testInitState: ExtendedTest,
  customExtractions:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    | null
    | undefined,
) {
  return getCustomExtractionTestName(
    testInitState.data.base,
    customExtractions,
  );
}

function createCompleteTestList(
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
  testSuite: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >,
): ExtendedTest {
  const template: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >["tests"]["nodes"][0]["reportTemplate"] = {
    ...reportTemplate.node,
    __typename: "ReportTemplate",
  };

  const testSuiteModuleCode = testSuite.moduleCode;

  const testInitState: ExtendedTest = getExtendTestFromTemplates(
    reportTemplate,
    template,
    testSuiteModuleCode,
  );

  const selectedTest = getSelectedTestsFromData(
    testSuite.tests,
    reportTemplate.node.code,
  );
  const categoryCode = getCategoryCode(reportTemplate);

  const testName =
    categoryCode === CUSTOM_EXTRACTION_CATEGORY
      ? getTestNameForCustomExtraction(
          testInitState,
          testSuite.customExtractions,
        )
      : testInitState.data.base.name;

  const testSummary =
    categoryCode === CUSTOM_EXTRACTION_CATEGORY
      ? getCustomExtractionTooltip(testName)
      : testInitState.data.base.automatorSummary;

  if (selectedTest) {
    return {
      data: {
        ...testInitState.data,
        ...selectedTest,
        base: {
          ...testInitState.data.base,
          name: testName,
          automatorSummary: testSummary,
        },
      },
      extended: {
        ...testInitState.extended,
        selected: true,
        category: categoryCode,
        moduleCode: testSuiteModuleCode,
      },
    };
  }

  return {
    data: {
      ...testInitState.data,
      base: {
        ...reportTemplate.node,
        name: testName,
        automatorSummary: testSummary,
        unit: reportTemplate.node.metadata?.unit ?? ReportTemplateUnit.UrLs,
        category: reportTemplate.node.category ?? "other",
      },
    },
    extended: {
      ...testInitState.extended,
      category: categoryCode,
      moduleCode: testSuiteModuleCode,
    },
  };
}

function getCategoryIndex(categories: TestCategory[], test: ExtendedTest) {
  return categories.findIndex(
    (category) => category.code === test.extended.category,
  );
}

function getNewCategory(
  categoryCode: string,
  categoryName: string,
  testList: ExtendedTest,
) {
  const name = "Tests for " + categoryName;
  return {
    code: categoryCode,
    name: name,
    tests: [testList],
    selected: false,
  };
}

/* eslint-disable fp/no-mutating-methods, max-statements */
function addTestToCategory(
  categories: TestCategory[],
  indexOfCategory: number,
  test: ExtendedTest,
) {
  categories[indexOfCategory].tests.push(test);
}

function getCategoryName(
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
) {
  if (reportTemplate.node.code.startsWith("custom_extraction_"))
    return "Custom extractions";
  return (
    reportTemplate.node.categories.find(
      (e) => e.code === reportTemplate.node.category,
    )?.name ??
    reportTemplate.node.category ??
    "Other"
  );
}

function addToOrCreateNewCategoryWithTest(
  testsCategories: TestCategory[],
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
  testSuite: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >,
) {
  const test = createCompleteTestList(reportTemplate, testSuite);
  const indexOfCategory = getCategoryIndex(testsCategories, test);
  if (indexOfCategory > -1) {
    addTestToCategory(testsCategories, indexOfCategory, test);
  } else {
    const categoryCode = getCategoryCode(reportTemplate);
    const newCategory: TestCategory = getNewCategory(
      categoryCode,
      getCategoryName(reportTemplate),
      test,
    );
    testsCategories.push(newCategory);
  }
}

function addNewTestCategoryForCustomExtraction(
  reportTemplate: GetAutomatorReportTemplatesQuery["getAutomatorReportTemplates"]["edges"][0],
  testSuite: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >,
  testsCategories: TestCategory[],
) {
  const customExtractionInReportTemplate = isCustomExtractionInReportTemplate(
    reportTemplate.node,
    testSuite.customExtractions,
  );
  if (customExtractionInReportTemplate) {
    addToOrCreateNewCategoryWithTest(
      testsCategories,
      reportTemplate,
      testSuite,
    );
  }
}

function getNewTestCategories(
  allReportTemplates: AllReportTemplates[],
  testSuite: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >,
) {
  const testsCategories: TestCategory[] = [];
  allReportTemplates.forEach((reportTemplate) => {
    if (reportTemplate.node.code.startsWith("custom_extraction_")) {
      addNewTestCategoryForCustomExtraction(
        reportTemplate,
        testSuite,
        testsCategories,
      );
    } else {
      addToOrCreateNewCategoryWithTest(
        testsCategories,
        reportTemplate,
        testSuite,
      );
    }
  });
  return testsCategories;
}

/* eslint-disable fp/no-mutating-methods, fp/no-mutation */
function sortCategoriesAndTheirTestsByName(testsCategories: TestCategory[]) {
  return testsCategories.sort(compareCategoryByName).map((cat) => {
    return {
      name: cat.name,
      code: cat.code,
      selected: cat.selected,
      tests: cat.tests.sort(compareTestByName),
    };
  });
}

/* eslint-enable fp/no-mutating-methods, fp/no-mutation */
export function getCategories(
  allReportTemplates: AllReportTemplates[],
  testSuite: NonNullable<
    GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
  >,
) {
  const testsCategories: TestCategory[] = sortCategoriesAndTheirTestsByName(
    getNewTestCategories(allReportTemplates, testSuite),
  );
  return [recommendedTestsCategory]
    .concat(testsCategories)
    .filter(
      (category) =>
        category.tests.length > 0 ||
        category.code === CUSTOM_EXTRACTION_CATEGORY ||
        category.code === RECOMMENDED_TESTS_CATEGORY_CODE,
    );
}

function compareCustomExtractions(
  extractionA:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >[0]
    | undefined,
  extractionB:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >[0]
    | undefined,
) {
  return (
    extractionA?.regex !== extractionB?.regex ||
    extractionA?.label !== extractionB?.label ||
    extractionA?.cleanHtmlTags !== extractionB?.cleanHtmlTags ||
    extractionA?.matchNumberFrom !== extractionB?.matchNumberFrom ||
    extractionA?.matchNumberTo !== extractionB?.matchNumberTo
  );
}

function areCustomExtractionDifferentLength(
  extractionsA: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["customExtractions"]
  >,
  extractionsB: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["customExtractions"]
  >,
) {
  return (
    (extractionsB.length === 0 && extractionsA.length !== 0) ||
    (extractionsA.length === 0 && extractionsB.length !== 0)
  );
}

function areCustomExtractionsSameLength(
  extractionsA: NonNullable<
    NonNullable<
      GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
    >["customExtractions"]
  >,
  extractionsB: NonNullable<
    NonNullable<
      GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
    >["customExtractions"]
  >,
) {
  return extractionsA.length === 0 && extractionsB.length === 0;
}

function mapCustomExtractionsToDifferenceResult(
  extractionsA: NonNullable<
    NonNullable<
      GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
    >["customExtractions"]
  >,
  extractionsB: NonNullable<
    NonNullable<
      GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
    >["customExtractions"]
  >,
) {
  return extractionsA
    .map((extraction, index) => {
      return compareCustomExtractions(extractionsB[index], extraction);
    })
    .reduce((prev, next) => {
      return prev || next;
    });
}

function areCustomExtractionsUndefinedsDifferent(
  extractionsA:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    | undefined,
  extractionsB:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    | undefined,
) {
  return (!!extractionsA && !extractionsB) || (!extractionsA && !!extractionsB);
}

export function areCustomExtractionArraysDifferent(
  extractionsA:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    | undefined,
  extractionsB:
    | NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    | undefined,
) {
  if (Array.isArray(extractionsB) && Array.isArray(extractionsA)) {
    if (areCustomExtractionsSameLength(extractionsA, extractionsB)) {
      return false;
    } else if (areCustomExtractionDifferentLength(extractionsA, extractionsB)) {
      return true;
    } else {
      return mapCustomExtractionsToDifferenceResult(extractionsA, extractionsB);
    }
  } else {
    return areCustomExtractionsUndefinedsDifferent(extractionsA, extractionsB);
  }
}

export function isTestInValid(
  test: ExtendedTest,
  absoluteThresholdUpLimit: number,
) {
  return (
    isNaN(test.data.absoluteThreshold) ||
    test.data.absoluteThreshold > absoluteThresholdUpLimit ||
    test.data.absoluteThreshold < 1
  );
}

export function isAnySelectedTestInvalid(
  categories: TestCategory[],
  absoluteThresholdUpLimit: number,
) {
  return (
    getSelectedTests(categories).filter((test) =>
      isTestInValid(test, absoluteThresholdUpLimit),
    ).length > 0
  );
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

// eslint-disable-next-line max-params
export function getCreateTestsMutationVariables(
  tests: ExtendedTest[],
  testSuiteId: string,
): CreateTestsMutationVariables {
  return {
    rulesAndThresholds: tests.map((test) => ({
      testSuiteId,
      relativeThreshold: test.data.relativeThreshold,
      absoluteThreshold: test.data.absoluteThreshold,
      thresholdType: test.data.thresholdType,
      reportTemplateCode: test.data.base.code,
      severity: test.data.severity,
      thresholdPredicate: test.data.thresholdPredicate,
      automaticThresholdEnabled: test.data.automaticThresholdEnabled,
      createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
      jiraTicketCustomNote: test.data.jiraTicketCustomNote,
    })),
  };
}

export function getUpdateTestsMutationVariables(
  tests: ExtendedTest[],
): UpdateTestsMutationVariables {
  return {
    rulesAndThresholds: tests.map((test) => ({
      testId: test.data.id,
      relativeThreshold: test.data.relativeThreshold,
      absoluteThreshold: test.data.absoluteThreshold,
      thresholdType: test.data.thresholdType,
      severity: test.data.severity,
      thresholdPredicate: test.data.thresholdPredicate,
      automaticThresholdEnabled: test.data.automaticThresholdEnabled,
      createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
      jiraTicketCustomNote: test.data.jiraTicketCustomNote,
    })),
  };
}
