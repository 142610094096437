import { jsx as _jsx } from "react/jsx-runtime";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { sharedResources } from "../locale/sharedResources";
import { useBrand } from "../brand/BrandProvider";
import { getFormatters } from "./interpolationFormatter";
import { supportedLanguages } from "./supportedLanguages";
import { merge } from "lodash";
export function I18nProvider(props) {
    const brand = useBrand();
    const i18nInstance = React.useMemo(() => {
        var _a;
        const languages = supportedLanguages.map((lng) => lng.code);
        const defaultLng = (_a = supportedLanguages.find((lng) => lng.default)) === null || _a === void 0 ? void 0 : _a.code;
        const instance = i18n.createInstance();
        instance
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
            supportedLngs: languages,
            compatibilityJSON: "v3",
            debug: process.env.NODE_ENV === "development",
            fallbackNS: "common",
            fallbackLng: defaultLng,
            interpolation: {
                escapeValue: false,
                defaultVariables: brand.i18nVariables,
            },
            detection: {
                order: ["querystring", "localStorage", "navigator"],
                caches: ["localStorage"],
            },
            resources: {
                en: merge(sharedResources["en"], props.resources["en"]),
                ja: merge(sharedResources["ja"], props.resources["ja"]),
            },
            react: {
                useSuspense: false,
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ["strong", "b", "ul", "li", "br", "i"],
            },
        });
        getFormatters(languages).forEach(([key, formatter]) => {
            var _a;
            (_a = instance.services.formatter) === null || _a === void 0 ? void 0 : _a.add(key, formatter);
        });
        return instance;
    }, [brand.i18nVariables, props.resources]);
    return (_jsx(I18nextProvider, { i18n: i18nInstance, children: props.children }));
}
