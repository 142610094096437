import { Box, makeStyles, Typography } from "@material-ui/core";
import notFoundGraphic from "../../../images/no-permissions-graphic.svg";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    maxWidth: 160,
  },
  img: {
    width: "100%",
  },
  wrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 505,
    margin: "0 auto",
    padding: theme.spacing(8),
  },
  header: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    textAlign: "center",
    color: theme.palette.navy[300],
  },
}));

export const MissingDataBox = (props: {
  message: string;
  dataTestId: string;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper} data-testid={props.dataTestId}>
      <Box className={classes.imgWrapper}>
        <img
          src={notFoundGraphic}
          alt="404 not found graphic"
          width={160}
          height={131}
          className={classes.img}
          data-testid="not-found-graphic"
        />
      </Box>
      <Typography
        variant="h2"
        className={classes.header}
        color="textPrimary"
        data-testid="not-found-title"
      >
        {props.message}
      </Typography>
    </Box>
  );
};
