import { Divider, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { numericSort } from "../../../../../_common/utils/array/numeric_sort/numericSort";
import { TestCategory, ExtendedTest } from "../common/CreateTests.interfaces";
import { ChooseTestsSelectionCheckBox } from "./ChooseTestsSelectionCheckBox";

interface CategoryTestsListProps {
  category: TestCategory;
  tests: ExtendedTest[];
  onTestSelectionChange: (test: ExtendedTest, selection: boolean) => void;
  maxLimitOfSelectedTestsReached: boolean;
  sortTests?: boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 600,
    margin: theme.spacing(1, 0),
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
  },
}));

export function CategoryTestsList(props: CategoryTestsListProps) {
  const { sortTests = true } = props;
  const classes = useStyles();
  const { category, tests } = props;
  const theme = useTheme();

  const testsFiltered = sortTests ? numericSort(tests) : tests;

  return testsFiltered.length > 0 ? (
    <div
      key={category.code}
      data-testid={`${category.code}-category-box`}
      data-cy={`${category.code}-category-box`}
    >
      <Divider className={classes.divider} />
      <Typography color="textPrimary" className={classes.title}>
        {category.name}
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ paddingTop: theme.spacing(2), paddingBottom: "4px" }}>
        {testsFiltered.map((test) => (
          <div
            style={{ marginBottom: theme.spacing(2) }}
            key={test.data.base.code + "-checkbox" + test.data.base.name}
          >
            <ChooseTestsSelectionCheckBox
              disabled={props.maxLimitOfSelectedTestsReached}
              test={test}
              onChange={(selected) =>
                props.onTestSelectionChange(test, selected)
              }
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
}
