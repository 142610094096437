import React, { useContext } from "react";
import { ExtendedTest } from "../common/CreateTests.interfaces";
import { Grid, useTheme } from "@material-ui/core";
import { useStyles } from "./ChooseTestsSelectionListStyles";
import {
  getSelectedTests,
  nonMutationSortByTestName,
  getVisibleTests,
  getRenderableCategories,
} from "../common/CreateTestsCommonUtils";
import { ChooseTestsSelectionCheckBox } from "./ChooseTestsSelectionCheckBox";
import { shouldRenderRecomendedTests } from "./ChooseTestsSelectionListUtils";
import { CategoryTestsList } from "./CategoryTestsList";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { numericSort } from "../../../../../_common/utils/array/numeric_sort/numericSort";
import { ChooseTestsFormCounters } from "./ChooseTestsFormCounters";
import { ChooseTestsState } from "../ChooseTestsForm/chooseTestsReducer";
import { RECOMMENDED_TESTS_CATEGORY_CODE } from "../ChooseTestsForm/CreateTestsCommonConst";

export interface ChooseTestsSelectionListProps {
  chooseTestsFormState: ChooseTestsState;
  clearSubmitError: () => void;
  maxHeight: number;
  renderingProgressed?: () => void;
  sortTests?: boolean;
  disableSelection: boolean;
}

// eslint-disable-next-line max-statements, max-lines-per-function
export function ChooseTestsSelectionList(props: ChooseTestsSelectionListProps) {
  const { sortTests = true } = props;
  const { categories, filter } = props.chooseTestsFormState;
  const classes = useStyles(props);
  const theme = useTheme();

  const selectedTestsSortedByName = sortTests
    ? nonMutationSortByTestName(getSelectedTests(categories))
    : getSelectedTests(categories);

  const selectedTestsSortedNumerical = sortTests
    ? numericSort(selectedTestsSortedByName)
    : selectedTestsSortedByName;

  const recommendedTestsCategories = categories.filter(
    (cat) => cat.code === RECOMMENDED_TESTS_CATEGORY_CODE,
  );

  const renderableCategories = getRenderableCategories(filter, categories);

  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);

  const handleTestSelectionChange = (test: ExtendedTest, selected: boolean) => {
    props.clearSubmitError();
    chooseTestsFormDispatch({
      type: "TEST_SELECTION_CHANGE",
      payload: { test, isTestSelected: selected },
    });
  };

  return (
    <>
      <div className={classes.scrollableList}>
        <Grid container data-testid="selected-tests-box">
          {filter.showSelected && (
            <>
              <Grid item xs={12}>
                <ChooseTestsFormCounters
                  chooseTestsFormState={props.chooseTestsFormState}
                />
              </Grid>
              <Grid item className={classes.selectedTestsGrid}>
                {selectedTestsSortedNumerical.map((test) => (
                  <div
                    style={{ marginBottom: theme.spacing(2) }}
                    key={
                      test.data.base.code +
                      "-checkbox-in-selection" +
                      test.data.base.name
                    }
                  >
                    <ChooseTestsSelectionCheckBox
                      test={test}
                      onChange={(selected) =>
                        handleTestSelectionChange(test, selected)
                      }
                      data-testid={
                        test.data.base.code + "-checkbox-in-selection"
                      }
                      disabled={props.disableSelection}
                    />
                  </div>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        {shouldRenderRecomendedTests(filter, categories) &&
          recommendedTestsCategories.map((category) => {
            const testsToRender = categories
              .flatMap((cat) => cat.tests)
              .filter((test) => test.extended.recomended);
            const filteredTests = getVisibleTests(
              testsToRender,
              filter.textToSearchBy,
              filter.showSelected,
            );
            return (
              <CategoryTestsList
                maxLimitOfSelectedTestsReached={props.disableSelection}
                key={category.code + category.name}
                category={category}
                tests={filteredTests}
                onTestSelectionChange={handleTestSelectionChange}
                sortTests={sortTests}
              />
            );
          })}
        {renderableCategories.map((category) => {
          const shouldRemoveRecomendedTest = shouldRenderRecomendedTests(
            filter,
            categories,
          );
          const testsToRender = getVisibleTests(
            category.tests,
            filter.textToSearchBy,
            filter.showSelected,
          ).filter((test) =>
            shouldRemoveRecomendedTest ? !test.extended.recomended : true,
          );

          return (
            <CategoryTestsList
              key={category.code + category.name}
              category={category}
              tests={testsToRender}
              onTestSelectionChange={handleTestSelectionChange}
              maxLimitOfSelectedTestsReached={props.disableSelection}
              sortTests={sortTests}
            />
          );
        })}
      </div>
    </>
  );
}
