import { ApolloError } from "@lumar/shared";
import {
  ModuleCode,
  useGetReportCategoriesQuery,
  useGetAllReportTemplatesQuery,
} from "../../../../../graphql";
import { ReportCategory } from "../ChooseHealthScoreTestsForm/ChooseHealthScoreTestsFormUtils";
import { useMemo } from "react";
import { moduleTopLevelCategory } from "../ChooseHealthScoreTestsForm/CreateTestsCommonConst";

export function useFilteredReportCategories(moduleCode: ModuleCode): {
  data: ReportCategory[];
  loading?: boolean;
  error?: ApolloError;
} {
  const {
    loading: loadingTemplates,
    data: reportTemplates,
    error: reportTemplatesError,
  } = useGetAllReportTemplatesQuery({
    variables: {
      filter: { primaryReportCategoryCode: { ne: "removed" } },
    },
  });

  const {
    loading: loadingCategories,
    data: reportCategories,
    error: reportCategoriesError,
  } = useGetReportCategoriesQuery({
    variables: {
      filter: { healthScore: { eq: true } },
    },
  });

  const filteredReportTemplates =
    reportTemplates?.getReportTemplates.edges.filter((e) =>
      e.node.supportedModules.find((m) => m === moduleCode),
    );

  const categories = useMemo(
    () =>
      reportCategories?.getReportCategories?.edges
        .filter(
          (c) =>
            filteredReportTemplates?.find(
              (r) => r.node.category === c.node.code,
            ) ||
            c.node.code === moduleTopLevelCategory[moduleCode] ||
            c.node.parentCode === moduleTopLevelCategory[moduleCode],
        )
        .map<ReportCategory>((c) => c.node) ?? [],
    [
      filteredReportTemplates,
      moduleCode,
      reportCategories?.getReportCategories?.edges,
    ],
  );

  return {
    data: categories,
    loading: loadingTemplates || loadingCategories,
    error: reportCategoriesError ?? reportTemplatesError,
  };
}
